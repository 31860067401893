<template>
    <v-list-item link @click="redirect(subModule.path)" dense>
      <v-list-item-content>
        <v-list-item-title v-html="subModule.name">

        </v-list-item-title>
      </v-list-item-content>
      <v-list-item-icon>
        <v-btn icon :color="starredComponent ? 'primary' : 'grey'" @click.stop="toggleStar" small>
          <v-icon>mdi-bookmark</v-icon>
        </v-btn>
      </v-list-item-icon>
    </v-list-item>
</template>

<script>
import Vuex from "vuex"
import PlaneteOnlineService from "@/Services/PlaneteOnlineService";

export default {
  name: "SubModule",
  props: {
    starred: Boolean,
    subModule: [Array, Object],
    module: [Array, Object]
  },
  computed: {
    ...Vuex.mapState(["widgetBoard"])
  },
  data() {
    return {
      starredComponent: this.starred,
      widgetModule: null
    }
  },
  methods: {
    htmlDecode: function (input) {
      let doc = new DOMParser().parseFromString(input, "text/html");
      return doc.documentElement.textContent;
    },
    redirect: function (subModuleLink) {
      if (subModuleLink[0] == ".") subModuleLink = subModuleLink.substr(1)
      if (subModuleLink[0] != "/") subModuleLink = "/" + subModuleLink
      window.open(subModuleLink, "_blank")
    },
    toggleStar: async function () {
      this.starredComponent = !this.starredComponent
      this.$store.commit("starSubModule", {
        starred: this.starredComponent,
        moduleId: this.module.id,
        submoduleId: this.subModule.id
      })
      let store = this.$_.pick(this.$store.state, ["widgetBoard"])
      await PlaneteOnlineService.postInterface(store)
    },
  },
  mounted() {
    let widgetModule = this.$store.getters.getWidgetDatas("modules").modules.starred
    if (widgetModule.filter(a => a.module == this.module.id && a.submodule == this.subModule.id).length > 0) {
      this.starredComponent = true
    }
  }
}
</script>

<style scoped>
.foundOccurence {
  text-decoration: underline;
}
</style>